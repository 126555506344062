import React from 'react'

import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import { useLocaleRedirection } from '@arch-log/webapp.lib/react.hook'

/**
 */
const Page = () => {
  useLocaleRedirection('/')

  return null
}

export default Page

/**
 */
export const Head = ({ pageContext }) => {
  return <PageHeadTemplates.Language language={pageContext?.language} />
}
